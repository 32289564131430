<template>
  <v-container fluid grid-list-md>
    <v-card max-width="1200" class="mx-auto pa-5">
      <v-card-title class="text-h5">
        {{ name }}
      </v-card-title>

      <v-card-subtitle v-if="(hotelItems.length == 0)" class="pa-5">
        Aktuell sind keine Hotels vorhanden, für die Bewertungen abgegeben werden können.
      </v-card-subtitle>

      <v-card-subtitle v-if="(hotelItems.length > 0)" class="pa-5">
        Hier haben Sie die Möglichkeit, Bewertungen für besuchte Hotels zu hinterlassen:
      </v-card-subtitle>

      <v-form v-if="(hotelItems.length > 0)" ref="hotelReviewForm" v-model="isFormValid"
        @submit.prevent="sendHotelReviewForm" class="pa-5">
        <!-- <v-select label="Austragungsjahr" :items="['2022', '2023']"></v-select> -->
        <v-select v-model="selectedHotel" label="Hotel" :rules="hotelRules" :items="hotelNames"></v-select>

        <section v-if="selectedHotelCompetitionInfo">
          Für das Wettspiel "{{ selectedHotelCompetitionInfo.name }}" vom
          {{ moment(selectedHotelCompetitionInfo.competitionStartDate, 'YYYY-MM-DD').format("DD.MM.YYYY") }}
          zum
          {{ moment(selectedHotelCompetitionInfo.competitionEndDate, 'YYYY-MM-DD').format("DD.MM.YYYY") }}
          wurde das Hotel "{{ selectedHotel.split(' :: ')[0] }}"" für Sie gebucht.
          <br />
          Bitte hinterlassen Sie nachfolgend eine Bewertung des Hotels für uns:
          <br /><br />
        </section>

        <v-rating v-model="rating" :rules="ratingRules" bg-color="orange-lighten-1" color="#43A382"></v-rating>
        <v-text-field v-model="comment" :rules="commentRules" label="Kommentar (optional)"></v-text-field>
        <v-btn block type="submit" class="mt-2" :disabled="!isFormValid">
          Absenden
        </v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Hotelbewertung",
  data() {
    return {
      name: "Hotelbewertung",
      backend: process.env.VUE_APP_STRAPI_API_URL,
      isFormValid: false,
      hotelbuchungen: [],
      hotel: "",
      // hotelItems: ["Platzhalter0", "Platzhalter1", "Platzhalter2"],
      hotelRules: [(value) => !!value || "Diese Angabe ist erforderlich"],
      rating: 0,
      ratingRules: [],
      comment: "",
      commentRules: [
        // (value) => !!value || "Diese Angabe ist erforderlich",
      ],
      userId: null,
      competitions: [],
      hotelNames: [],
      hotelItems: [],
      hotelRatings: [],
      selectedHotel: {},
      selectedHotelId: null,
      averageRating: null,
      selectedHotelCompetitionInfo: false
    };
  },

  async created() {
    this.userId = this.parseJwt(localStorage.getItem("token")).id;
    let yourDate = new Date().toISOString().split("T")[0];
    // let userId = "642e8d323326eebbcdc8408d"

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // strapi 3 filtering
      // https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html#filters
      // we want to filter competitions / wettspiele that have already taken place
      // url: `${this.backend}/abrechnungens?_where[0][competition.competitionEndDate_lt]=2023-04-13`,

      //
      url: `${this.backend}/competitions?_where[0][competitionEndDate_lt]=${yourDate}&_where[1][referees.id_eq]=${this.userId}`,
      headers: {
        Authorization: localStorage.getItem("token") || "",
      },
    };

    axios
      .request(config)
      .then((response) => {
        this.competitions = response.data;
        let result = [];
        if (this.competitions.length > 0) {
          for (const competition of this.competitions) {
            if (competition.hotelbewertungs) {
              let userHasAlreadyRated = competition.hotelbewertungs.filter(obj => {
                return obj.users_permissions_user == this.userId
              })[0]

              if (!userHasAlreadyRated) {
                if (competition.hotel) {
                  result.push(competition.hotel);
                  // this.hotelNames.push(competition.hotel.name)
                  this.hotelNames.push(`${competition.hotel.name} :: ${competition.name}`)
                }
              }
            } else {
              if (competition.hotel) {
                result.push(competition.hotel);
                // this.hotelNames.push(competition.hotel.name)
                this.hotelNames.push(`${competition.hotel.name} :: ${competition.name}`)
              }
            }
          }
          this.hotelItems = result.sort();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    selectedHotel(hotelName) {
      // this.refreshCompetitionInfo(newValue);

      for (const competition of this.competitions) {
        let exploded = hotelName.split(' :: ')
        if (competition && competition.hotel) {
          if (competition.hotel.name == exploded[0]) {
            if (competition.name == exploded[1]) {
              this.selectedHotelCompetitionInfo = competition
            }
          }
        }
      }
      // this.selectedHotelCompetitionInfo = this.competitions.filter(obj => {
      //   return obj.hotel.name == hotelName.split(' :: ')[0]
      // })[0]
    },
  },
  // computed: {
  //   // hotelItems() {
  //   //   let result = [];
  //   //   if (this.competitions.length > 0) {
  //   //     this.competitions.forEach((element) => {
  //   //       console.log(element.hotel.name)
  //   //       result.push(element.hotel);
  //   //       // this.hotelNames.push(element.hotel.name)
  //   //     });
  //   //   }
  //   //   return result.sort();

  //   //   //   let result = [];
  //   //   //   this.abrechnungen.forEach((element) => {
  //   //   //     result.push(element.competition.name);
  //   //   //   });
  //   //   //   return result.sort();
  //   // },
  // },
  methods: {
    // refreshCompetitionInfo(newValue) {
    //   this.selectedHotelCompetitionInfo = this.competitions.filter(obj => {
    //     return obj.hotel.name == newValue
    //   })
    //   console.log('this.selectedHotelCompetitionInfo')
    //   console.log(this.selectedHotelCompetitionInfo)
    //   // return null;
    // },

    sendHotelReviewForm() {
      axios
        .post(
          `${this.backend}/hotelbewertungs`,
          {
            rating: this.rating,
            comment: this.comment,
            users_permissions_user: this.userId,
            hotel: this.selectedHotelCompetitionInfo.hotel.id,
            competition: this.selectedHotelCompetitionInfo.id
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token") || "",
            },
          }
        )
        .then(() => {
          // calculate and put new average hotel rating
          this.selectedHotelId = this.selectedHotelCompetitionInfo.hotel.id
          this.setNewAverage()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setNewAverage() {
      const hotelId = this.selectedHotelId
      axios
        .get(
          `${this.backend}/hotelbewertungs?hotel._id_eq=${hotelId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token") || "",
            },
          }
        )
        .then((response) => {
          this.averageRating = this.calcAverage(response.data)
          this.putAvgRating()

        })
        .catch((error) => {
          console.log(error);
        });
    },
    calcAverage(ratings) {
      let avg = 0;
      for (const rating of ratings) {
        avg += rating.rating
      }
      avg = parseFloat(avg / ratings.length).toFixed(2)
      return avg
    },
    putAvgRating() {
      const hotelId = this.selectedHotelId

      axios
        .put(
          `${this.backend}/hotels/${hotelId}`,
          {
            "averageRating": this.averageRating
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token") || "",
            },
          }
        )
        .then(() => {
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
  },
};
</script>
